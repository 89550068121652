/* CadastroMchatRf.css */

.mchat-container {
    padding: 2em;
    background-color: #e7e7e7;
    border-radius: 1em;
    max-width: 1500px;
    margin: 0 auto;
}

.mchat-header {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: 1em;
}

.mchat-header div {
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-bottom: 1em;
}

.back-icon {
    font-size: 1.5em;
    color: #4a63d0;
    cursor: pointer;
}

.mchat-header h2 {
    font-size: 1.8em;
    font-weight: bold;
    color: #6a6a6a;
    margin-left: 0.7em;
}

.mchat-header p {
    color: #6a6a6a;
    font-size: 1.4rem;
    margin: 0.5em 0;
    margin-left: 1em;
}

.mchat-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(485px, 1fr));
    gap: 1em;
    background-color: #ffffff;
    padding: 3em;
    border-radius: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mchat-form input {
    padding: 25px;
    width: 95% !important;
    font-size: 1.3rem;
    border: 2px solid #b3d4ff;
    border-radius: 10px;
    outline: none;
    transition: border-color 0.3s;
}

.mchat-form input:focus {
    border-color: #4a63d0;
}

.continue-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #4CAF50;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1em;
    transition: background-color 0.3s ease;
}

.continue-btn:hover {
    background-color: #45a049;
}

.continue-btn svg {
    margin-left: 0.5em;
}

.button-org-info-cadastro-m-chat {
    display: flex;
    justify-content: end;
}

.continue-btn-2 svg {
    margin-left: 0.5em;
    transform: rotate(180deg);
    /* Rotaciona o ícone para a direita */
}

/* PerguntasMchatRf.css */
/* PerguntasMchatRf.css */

.perguntas-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 20px;
    padding: 20px;
}

.pergunta-card {
    background-color: #fff;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 200px;
    /* Defina uma altura mínima para os cards */
}

.pergunta-text {
    font-weight: bold;
    font-size: 15pt;
    margin-bottom: 1em;
    color: #666;
}

.exemplo-text {
    font-size: 16pt;
    color: #666;
    margin-bottom: 1em;
}

.resposta-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.resposta-sim,
.resposta-nao {
    padding: 8px 16px;
    border: none;
    border-radius: 1em;
    cursor: pointer;
    width: 125px;
    height: 40px;
    /* Altura fixa para todos os botões */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
}

.resposta-sim {
    background-color: #28a745;
    color: white;
}

.resposta-nao {
    background-color: #dc3545;
    color: white;
}


.continuar-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.continuar-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.continuar-btn svg {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
}

.button-container {
    display: flex;
    justify-content: space-between;
    padding: 0em;
    width: 95%;
    margin: 0 auto;
}

.continuar-btn,
.voltar-btn {
    background-color: #28a745;
    color: white;
    padding: 20px 35px;
    border: none;
    border-radius: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16pt;
}

.voltar-btn {
    background-color: #6c757d;
}

.continuar-btn:hover,
.voltar-btn:hover {
    opacity: 0.9;
}



.pergunta-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.pergunta-text {
    font-weight: bold;
    margin-bottom: 10px;
}

.exemplo-text {
    color: #666;
    margin-bottom: 20px;
}

.resposta-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.resposta-sim,
.resposta-nao {
    background-color: #ccc;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    font-weight: bold;
}

.resposta-sim.selected {
    background-color: #28a745;
    /* Verde para resposta Sim */
}

.resposta-nao.selected {
    background-color: #dc3545;
    /* Vermelho para resposta Não */
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.voltar-btn,
.continuar-btn,
.finalizar-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.voltar-btn {
    background-color: #6c757d;
    color: white;
}

.continuar-btn {
    background-color: #007bff;
    color: white;
}

.finalizar-btn {
    background-color: #28a745;
    color: white;
}

.date-picker-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    /* Ajuste conforme necessário */
}

.date-picker-container {
    position: relative;
}

.date-picker-label {
    position: absolute;
    top: -14%;
    left: 10px;
    transform: translateY(-50%);
    color: #999;
    font-size: 16px;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    background: white;
    padding: 0 5px;
}

.date-picker-label.has-value,
.date-picker-input:focus+.date-picker-label {
    top: -10px;
    font-size: 12px;
    color: #333;
}

.date-picker-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.icon-i {
    color: rgb(74, 99, 208);
    margin-left: 24%;
    cursor: pointer;
    visibility: visible;
    position: absolute;
    top: 17%;
    font-size: 49px;
}

@media (min-width: 768px) and (max-width: 1400px) {
    .icon-i {
        color: rgb(74, 99, 208);
        margin-left: 30%;
        cursor: pointer;
        visibility: visible;
        position: absolute;
        top: 17%;
        font-size: 45px;
    }

}

@media (min-width: 720px) and (max-width: 1280px) {
    .icon-i {
        color: rgb(74, 99, 208);
        margin-left: 33%;
        cursor: pointer;
        visibility: visible;
        position: absolute;
        top: 17%;
        font-size: 45px;
    }
}

@media (max-width: 768px) {

    .icon-i {
        color: rgb(74, 99, 208);
        margin-left: 84%;
        cursor: pointer;
        visibility: visible;
        position: absolute;
        top: 17%;
        font-size: 35px;
    }



    .mchat-container {
        padding: 0em !important;
        background-color: #e7e7e7;
        border-radius: 1em;
        max-width: 1500px;
        margin: 0 auto;
    }

    .mchat-header p {
        color: #6a6a6a;
        font-size: 1.1rem !important;
        margin: 0.5em 0;
        margin-left: 1em;
        width: 90%;
    }

    .mchat-form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2em;
        background-color: #ffffff;
        padding: 1em !important;
        border-radius: 1em;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .mchat-form input {
        padding: 17px !important;
        font-size: 1rem !important;

    }

    .mchat-form {
        width: 80%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        background-color: #ffffff;
        padding: 1em;
        margin: 0 auto;
        border-radius: 1em;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }


    .continue-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #4CAF50;
        color: white;
        padding: 10px 20px !important;
        border: none;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        margin-top: 1em;
        transition: background-color 0.3s ease;
        margin-bottom: 1em;

        margin-right: 1em;
    }

    .button-org-info-cadastro-m-chat {
        display: flex;
        justify-content: center !important;
    }

    .perguntas-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)) !important;
        gap: 20px;
        padding: 0px;
    }

    .pergunta-text {
        font-weight: bold;
        font-size: 12pt !important;
        margin-bottom: 1em;
        color: #666;
    }


    .exemplo-text {
        font-size: 11pt !important;
        color: #666;
        margin-bottom: 1em;
    }

    .pergunta-card {
        background-color: #f8f8f8;
        padding: 2em;
        border-radius: 1em;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 170px !important;
    }

    .button-container {
        display: flex;
        justify-content: space-around !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }



    .mchat-container {

        background-color: #e7e7e7 !important;

    }


}